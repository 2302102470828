import React, { useState } from "react";
import {
    commonService,
    AppConfigData,
} from "../_services/common.service";
import _Skeleton, { _SkeletonType } from "./_Skeleton";
import _NoDataFound from "./_NoDataFound";
import { useHistory } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { alertService, AlertType } from "../_services/alert.service";
function Fees() {
    const history = useHistory();
    let data = commonService.getLoginUserData();
    const [Fees, setFees] = useState({ IsData: false, data: [] });
    const [PayFeesPara, setPayFeesPara] = useState({
        Item: {},
        UserAmount: 0,
        IsTakeFullFeesByOnline: false,
        UnpaidAmount: 0,
        errors: "",
    });
    const [showPaymentPopup, setshowPaymentPopup] = useState(false);
    const [IsPaying, setIsPaying] = useState(false);
    const [studentfeesdata, setstudentfeesdata] = useState({
        isdata: false,
        data: [],
    });
    const [feescheckbox, setfeescheckbox] = useState({
        feesid: "",
        id: "",
        check: false,
        feesvalue: 0,
    });

    let defaultActiveKey = "Fees";
    const params = new URLSearchParams(window.location.search);
    if (params.has("tab")) {
        if (params.get("tab") === "3") {
            defaultActiveKey = "Online transaction details";
        }
    }
    var prevval = 0;

    function BindFeesList() {
        setFees({ IsData: false, data: [] });

        const reqExam = {
            method: "POST",
            headers: commonService.getHeaders(),
        };
        fetch(AppConfigData.APIBasePath + "v1/fees/getdetailsbymember", reqExam)
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    setFees({ IsData: true, data: json.data });
                } else if (json.status_code === 0) {
                    setFees({ IsData: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
    }

    function OpenAmountPopup(item) {
        let PFP = {
            Item: item,
            UserAmount: item.IsTakeFullFeesByOnline ? item.UnpaidAmount : 0,
            IsTakeFullFeesByOnline: item.IsTakeFullFeesByOnline,
            UnpaidAmount: item.UnpaidAmount,
            LateFeeAmount: 0,
            errors: "",
        };

        //setPayFeesPara({
        //    Item: item,
        //    UserAmount: item.IsTakeFullFeesByOnline ? item.UnpaidAmount : 0,
        //    IsTakeFullFeesByOnline: item.IsTakeFullFeesByOnline,
        //    UnpaidAmount: item.UnpaidAmount,
        //    errors: "",
        //});
        setstudentfeesdata({ isdata: false, data: [] });
        const reqstudentfeesdata = {
            method: "POST",
            headers: commonService.getHeaders(),
            body: JSON.stringify({
                feesStructureScheduleID: item.FeesStructureScheduleID,
                requestFrom: "web",
            }),
        };
        fetch(
            AppConfigData.APIBasePath + "v1/fees/getstudentfeesstructuredetails",
            reqstudentfeesdata
        )
            .then((response) => response.json())
            .then(function (json) {
                if (json.status_code === 1) {
                    var a = json.data;
                    let LateFeeAmount = 0;
                    for (let i = 0; i < a.length; i++) {
                        a[i].IsCheck = a[i].IsLateFeeHead;
                        if (a[i].IsLateFeeHead) {
                            LateFeeAmount = LateFeeAmount + a[i].PayableAmount;
                        }
                    }
                    PFP.LateFeeAmount = LateFeeAmount;
                    PFP.UserAmount = item.IsTakeFullFeesByOnline ? item.UnpaidAmount + LateFeeAmount : LateFeeAmount;
                    setstudentfeesdata({ isdata: true, data: a });
                } else if (json.status_code === 0) {
                    setstudentfeesdata({ isdata: true, data: [] });
                } else if (json.status_code === 2) {
                    localStorage.clear();
                    history.push("/");
                }
            });
        setPayFeesPara(PFP);
        setshowPaymentPopup(true);
    }

    function CheckPaymentStatus(item) {
        if (item.TransactionStatus.toUpperCase() === "PENDING" && item.BookID === "00000000-0000-0000-0000-000000000000") {
            setFees({ IsData: false, data: [] });
            const reqstudentfeesdata = {
                method: "POST",
                headers: commonService.getHeaders(),
                body: JSON.stringify({
                    ID: item.PaymentTransactionDetailsID,
                    requestFrom: "web",
                }),
            };
            fetch(
                AppConfigData.APIBasePath + "v1/fees/checkpaymentstatus",
                reqstudentfeesdata
            )
                .then((response) => response.json())
                .then(function (json) {
                    if (json.status_code === 1) {
                        alertService.alert({
                            type: AlertType.Success,
                            message: json.message,
                        });
                        BindFeesList();
                    } else if (json.status_code === 0) {

                    } else if (json.status_code === 2) {
                        localStorage.clear();
                        history.push("/");
                    }
                }).catch((error) => { BindFeesList(); });
        }
    }

    function PayNowClick() {
        if (PayFeesPara.UserAmount !== null) {
            if (PayFeesPara.UserAmount !== "") {
                if (parseFloat(PayFeesPara.UserAmount) !== 0 && parseFloat(PayFeesPara.UserAmount) > parseFloat(PayFeesPara.LateFeeAmount)) {
                    if (
                        parseFloat(PayFeesPara.UserAmount) <= PayFeesPara.Item.UnpaidAmount + PayFeesPara.LateFeeAmount
                    ) {
                        setPayFeesPara({
                            ...PayFeesPara,
                            ["errors"]: "",
                        });
                        setIsPaying(true);
                        const req = {
                            method: "POST",
                            headers: commonService.getHeaders(),
                            body: JSON.stringify({
                                batchID: PayFeesPara.Item.BatchID,
                                feesStructuteID: PayFeesPara.Item.FeesStructuteID,
                                feesStructureScheduleID: PayFeesPara.Item.FeesStructureScheduleID,
                                unpaidAmount: PayFeesPara.Item.UnpaidAmount,
                                paymentGateWayMasterID: PayFeesPara.Item.PaymentGateWayMasterID,
                                requestFrom: "Web",
                                payAmount: parseFloat(PayFeesPara.UserAmount),
                                instituteName: data[0].InstituteName,
                                userDisplayName: data[0].DisplayName,
                                LateFeeAmount: parseFloat(PayFeesPara.LateFeeAmount)
                            }),
                        };
                        fetch(AppConfigData.APIBasePath + "v1/fees/getpaymenturl", req)
                            .then((response) => response.json())
                            .then(function (json) {
                                if (json.status_code === 1) {
                                    if (json.data !== null) {
                                        if (json.data !== "") {
                                            window.location.href = json.data;
                                        }
                                    }
                                }
                                else if (json.status_code === 0) {
                                    alertService.alert({
                                        type: AlertType.Error,
                                        message: json.message,
                                    });
                                    setIsPaying(false);
                                } else if (json.status_code === 2) {
                                    localStorage.clear();
                                    history.push("/");
                                } else {
                                    setIsPaying(false);
                                }
                            });
                    } else {
                        setPayFeesPara({
                            ...PayFeesPara,
                            ["errors"]:
                                "Payment amount can't be greater than, your unpaid amount " +
                                (PayFeesPara.Item.UnpaidAmount + PayFeesPara.LateFeeAmount),
                        });
                    }
                } else {
                    setPayFeesPara({
                        ...PayFeesPara,
                        ["errors"]: "Payment amount can't be blank , zero or less then and equal " + PayFeesPara.LateFeeAmount,
                    });
                }
            } else {
                setPayFeesPara({
                    ...PayFeesPara,
                    ["errors"]: "Payment amount can't be blank , zero or less then and equal " + PayFeesPara.LateFeeAmount,
                });
            }
        } else {
            setPayFeesPara({
                ...PayFeesPara,
                ["errors"]: "Payment amount can't be blank or zero",
            });
        }
    }

    React.useEffect(() => {
        if (commonService.getLoginRoleData("Fees", "IsView") && data != null && data != undefined && data.length != 0 && data[0].MemberType.toLowerCase() === "student") {
            BindFeesList();
        } else {
            history.push("/access");
        }
    }, []);

    function getfeescheckbox(e, index, a) {
        let Newunpaidfees = 0;
        for (let i = 0; i < studentfeesdata.data.length; i++) {
            if (i > index) {
                if (studentfeesdata.data[i].IsLateFeeHead) {
                    studentfeesdata.data[i].IsCheck = true;
                }
                else {
                    studentfeesdata.data[i].IsCheck = false;
                }
            }
        }
        studentfeesdata.data[index].IsCheck = e.target.checked;
        if (e.target.checked) {
            for (let i = index; i >= 0; i--) {
                studentfeesdata.data[i].IsCheck = e.target.checked;
            }
        }
        for (let i = 0; i < studentfeesdata.data.length; i++) {
            if (studentfeesdata.data[i].IsCheck) {
                Newunpaidfees =
                    Newunpaidfees +
                    (studentfeesdata.data[i].PayableAmount -
                        studentfeesdata.data[i].TotalPaid);
            }
        }
        setPayFeesPara({
            ...PayFeesPara,
            ["UserAmount"]: Newunpaidfees,
        });
    }

    function checkval(e) {
        setPayFeesPara({
            ...PayFeesPara,
            ["UserAmount"]: e.target.value,
        });
        for (let i = 0; i < studentfeesdata.data.length; i++) {
            studentfeesdata.data[i].IsCheck = studentfeesdata.data[i].IsLateFeeHead;
        }
    }

    return (
        <>
            <h1 className="page-header my-4">{commonService.LabelDisplayText("MenuFees")}</h1>
            <Tabs
                defaultActiveKey={defaultActiveKey}
                transition={false}
                id="noanim-tab-example"
                className="mb-3 timetable-liveclass"
            >
                <Tab eventKey="Fees" title={commonService.LabelDisplayText("MenuFees")}>
                    {!Fees.IsData ? (
                        <div className="row col-12">
                            <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                        </div>
                    ) : Fees.data.feesDetails.length === 0 ? (
                        <div className="col-12">
                            <_NoDataFound fontsize={"150px"} />
                        </div>
                    ) : (
                        <Table responsive className="fees-payment-table m-0">
                            <thead>
                                <tr>
                                    <th className="w-75px text-center">{commonService.LabelDisplayText("Year")}</th>
                                    <th>{commonService.LabelDisplayText("FeesName")}</th>
                                    <th className="text-right w-150px">{commonService.LabelDisplayText("Payableamount")}</th>
                                    <th className="text-right w-150px">{commonService.LabelDisplayText("Paidamount")}</th>
                                    <th className="text-right w-150px">{commonService.LabelDisplayText("Unpaidamount")}</th>
                                    <th className="w-75px">{commonService.LabelDisplayText("Pay")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Fees.data.feesDetails.map(
                                    (item, index) =>
                                        item.UnpaidAmount !== null &&
                                        parseFloat(item.UnpaidAmount) > 0 && (
                                            <tr key={index}>
                                                <td className="text-center">{item.BatchName}</td>
                                                <td>{item.DisplayName}</td>
                                                <td className="text-right">{item.Amount.toFixed(2)}</td>
                                                <td className="text-right">
                                                    {item.TotalPaidAmount.toFixed(2)}
                                                </td>
                                                <td className="text-right">
                                                    {item.UnpaidAmount.toFixed(2)}
                                                </td>
                                                <td>
                                                    {item.IsAbleToPayFees === true && item.PaymentGateWayMasterID !== null && commonService.getLoginRoleData("Fees", "IsCreate") &&
                                                        item.CounterID !== null &&
                                                        item.CounterUserID !== null ? (
                                                        <button
                                                            className="small-btn fees-pay-button"
                                                            onClick={() => OpenAmountPopup(item)}
                                                        >
                                                            {commonService.LabelDisplayText("Pay")}
                                                        </button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                )}
                            </tbody>
                        </Table>
                    )}
                </Tab>
                <Tab eventKey="Fees Receipt details" title={commonService.LabelDisplayText("FeesReceipt")}>
                    {!Fees.IsData ? (
                        <div className="row col-12">
                            <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                        </div>
                    ) : Fees.data.feesDetails.length === 0 ? (
                        <div className="col-12">
                            <_NoDataFound fontsize={"150px"} />
                        </div>
                    ) : (
                        <Table responsive className="fees-payment-table m-0">
                            <thead>
                                <tr>
                                    <th className="text-center w-75px">{commonService.LabelDisplayText("Year")}</th>
                                    <th>{commonService.LabelDisplayText("FeesName")}</th>
                                    <th className="text-right w-150px">{commonService.LabelDisplayText("Payableamount")}</th>
                                    <th className="text-right w-150px">{commonService.LabelDisplayText("Paidamount")}</th>
                                    <th className="text-right w-150px">{commonService.LabelDisplayText("Unpaidamount")}</th>
                                    <th className="view-receipt w-75px">{commonService.LabelDisplayText("Receipt")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Fees.data.feesDetails.map(
                                    (item, index) =>
                                        item.StudentFeesCollectionID !== null && (
                                            <tr key={index}>
                                                <td className="text-center">{item.BatchName}</td>
                                                <td>{item.DisplayName}</td>
                                                <td className="text-right">{item.Amount.toFixed(2)}</td>
                                                <td className="text-right">
                                                    {item.TotalPaidAmount.toFixed(2)}
                                                </td>
                                                <td className="text-right">
                                                    {item.UnpaidAmount.toFixed(2)}
                                                </td>
                                                <td>
                                                    <a
                                                        target="_blank"
                                                        href={
                                                            "http://erp.orataro.com/mobile_api/FeesReceipt?ClientID=" +
                                                            item.ClientID +
                                                            "&InstituteID=" +
                                                            item.InstituteID +
                                                            "&BatchID=" +
                                                            item.BatchID +
                                                            "&StudentID=" +
                                                            item.StudentID +
                                                            "&FeesStructureID=" +
                                                            item.FeesStructuteID +
                                                            "&ctoken="
                                                        }
                                                    >
                                                        <button className="small-btn d-flex align-items-center justify-content-center">
                                                            {commonService.LabelDisplayText("View")}
                                                        </button>
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                )}
                            </tbody>
                        </Table>
                    )}
                </Tab>
                <Tab
                    eventKey="Online transaction details"
                    title={commonService.LabelDisplayText("TransactionDetail")}
                >
                    {!Fees.IsData ? (
                        <div className="row col-12">
                            <_Skeleton type={_SkeletonType.Table} count={4} lineheight={30} />
                        </div>
                    ) : Fees.data.paymentTransactionDetails.length === 0 ? (
                        <div className="col-12">
                            <_NoDataFound fontsize={"150px"} />
                        </div>
                    ) : (
                        <Table responsive className="fees-payment-table m-0">
                            <thead>
                                <tr>
                                    <th className="text-center w-75px">{commonService.LabelDisplayText("Status")}</th>
                                    <th className="w-130px">{commonService.LabelDisplayText("Receipt")}</th>
                                    <th className="fees-name">{commonService.LabelDisplayText("FeesName")}</th>
                                    <th className="w-130px">{commonService.LabelDisplayText("Date")}</th>
                                    <th className="text-right w-100px">{commonService.LabelDisplayText("Amount")}</th>
                                    <th>{commonService.LabelDisplayText("paymentId")}</th>
                                    <th>{commonService.LabelDisplayText("Paidby")}</th>
                                    <th className="w-100px">{commonService.LabelDisplayText("From")}</th>
                                    <th className="w-75px">{commonService.LabelDisplayText("Check")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Fees.data.paymentTransactionDetails.map(
                                    (item, index) =>
                                        item.StudentFeesCollectionID !== null && (
                                            <tr key={index}>
                                                <td
                                                    title={item.TransactionStatus}
                                                    className="fees-transaction-status text-center w-100px"
                                                >
                                                    {item.TransactionStatus.toUpperCase() ===
                                                        "PENDING" && (
                                                            <i className="fa-duotone fa-spinner-third fees-status-pending"></i>
                                                        )}
                                                    {item.TransactionStatus.toUpperCase() ===
                                                        "SUCCESS" && (
                                                            <i className="fa-regular fa-circle-check fees-status-success"></i>
                                                        )}
                                                    {item.TransactionStatus.toUpperCase() === "ERROR" && (
                                                        <i className="fa-regular fa-circle-exclamation fees-status-error"></i>
                                                    )}
                                                </td>
                                                <td>{item.ReceiptNo}</td>
                                                <td>{item.StructureDisplayName}</td>
                                                <td>
                                                    {commonService.getDateInFormat(item.TransactionDate)}
                                                </td>
                                                <td className="text-right">
                                                    {item.TransactionAmount.toFixed(2)}
                                                </td>
                                                <td>{item.PaymentGetWayTranID}</td>
                                                <td>{item.UserName}</td>
                                                <td className="text-center">{item.TransactionFrom}</td>
                                                <td>
                                                    {
                                                        item.TransactionStatus.toUpperCase() === "PENDING" && item.BookID === "00000000-0000-0000-0000-000000000000" &&
                                                        <button className="small-btn d-flex align-items-center justify-content-center" title="Click Here For Check Your Payment Status" onClick={() => CheckPaymentStatus(item)}>
                                                            {commonService.LabelDisplayText("Check")}
                                                        </button>}</td>
                                            </tr>
                                        )
                                )}
                            </tbody>
                        </Table>
                    )}
                </Tab>
            </Tabs>

            <Modal
                show={showPaymentPopup}
                backdrop="static"
                keyboard={false}
                onHide={() => setshowPaymentPopup(false)}
                className=""
                contentClassName={""}
                dialogClassName={"width-50per"}
            >
                <Modal.Header className="">
                    <h5>{commonService.LabelDisplayText("PaymentDetails")}</h5>
                    <button
                        className="poopup-close-button"
                        onClick={() => setshowPaymentPopup(false)}
                    >
                        <i className="icon icon-fi-rr-cross"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            {studentfeesdata.isdata ? (
                                <>
                                    <Table responsive className="fees-payment-table m-0">
                                        <thead>
                                            {PayFeesPara.IsTakeFullFeesByOnline === false ? (
                                                <th className="w-75px text-center">{commonService.LabelDisplayText("Check")} </th>
                                            ) : (
                                                <></>
                                            )}
                                            <th className="text-left">{commonService.LabelDisplayText("Name")}</th>
                                            <th className="text-right w-130px">{commonService.LabelDisplayText("Payable")}</th>
                                            <th className="text-right w-130px">{commonService.LabelDisplayText("Discount")}</th>
                                            <th className="text-right w-130px">{commonService.LabelDisplayText("Paid")}</th>
                                            <th className="text-right w-130px">{commonService.LabelDisplayText("Unpaid")}</th>
                                        </thead>
                                        <tbody>
                                            {studentfeesdata.data.length !== 0 &&
                                                studentfeesdata.data.map((a, index) => (
                                                    <>{a.TotalPaid < a.PayableAmount ? <>
                                                        <tr className={a.IsLateFeeHead === true ? "islatefeehead" : ""}>
                                                            {PayFeesPara.IsTakeFullFeesByOnline === false ? (
                                                                <>
                                                                    {
                                                                        a.IsLateFeeHead === true ?
                                                                            <td className="text-center">
                                                                                <input
                                                                                    data-attr={a.IsCheck}
                                                                                    type="checkbox"
                                                                                    id={index}
                                                                                    checked={
                                                                                        a.IsCheck != null &&
                                                                                            a.IsCheck != undefined
                                                                                            ? a.IsCheck
                                                                                            : false
                                                                                    }
                                                                                />
                                                                            </td>
                                                                            :
                                                                            <td className="text-center">
                                                                                <input
                                                                                    data-attr={a.IsCheck}
                                                                                    type="checkbox"
                                                                                    id={index}
                                                                                    checked={
                                                                                        a.IsCheck != null &&
                                                                                            a.IsCheck != undefined
                                                                                            ? a.IsCheck
                                                                                            : false
                                                                                    }
                                                                                    onChange={(e) => {
                                                                                        getfeescheckbox(e, index, a);
                                                                                    }}
                                                                                />
                                                                            </td>
                                                                    }

                                                                </>
                                                            ) : (
                                                                <></>
                                                            )}

                                                            <td className="text-left">{a.Displayname}</td>
                                                            <td className="text-right">{a.PayableAmount}</td>
                                                            <td className="text-right">{a.DiscountAmount}</td>
                                                            <td className="text-right">{a.TotalPaid}</td>
                                                            <td className="text-right">{a.PayableAmount - a.TotalPaid}</td>
                                                        </tr>
                                                    </> : <></>}</>
                                                ))}
                                        </tbody>
                                    </Table>
                                </>
                            ) : (
                                <_Skeleton
                                    type={_SkeletonType.Table}
                                    count={4}
                                    lineheight={30}
                                />
                            )}
                        </div>
                        <div className="col-12 mt-3">
                            <b>{commonService.LabelDisplayText("YourUnpaidAmountIs")} {PayFeesPara.UnpaidAmount + PayFeesPara.LateFeeAmount}</b>
                        </div>
                        <div className="col-12">
                            {
                                PayFeesPara.IsTakeFullFeesByOnline ?
                                    <input
                                        type="number"
                                        placeholder="Enter Amount"
                                        disabled={PayFeesPara.IsTakeFullFeesByOnline}
                                        value={PayFeesPara.UserAmount}
                                        min={PayFeesPara.LateFeeAmount}
                                        max={PayFeesPara.UnpaidAmount + PayFeesPara.LateFeeAmount}
                                        className={`mt-4 title-textbox ${PayFeesPara.errors && "invalid"}`}
                                    />
                                    :
                                    <input
                                        type="number"
                                        placeholder="Enter Amount"
                                        onChange={(e) => checkval(e)}
                                        disabled={PayFeesPara.IsTakeFullFeesByOnline}
                                        value={PayFeesPara.UserAmount}
                                        min={PayFeesPara.LateFeeAmount}
                                        max={PayFeesPara.UnpaidAmount + PayFeesPara.LateFeeAmount}
                                        className={`mt-4 title-textbox ${PayFeesPara.errors && "invalid"}`}
                                    />
                            }
                            {PayFeesPara.errors && (
                                <span className="error-message">{PayFeesPara.errors}</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-secondary"
                        onClick={() => setshowPaymentPopup(false)}
                    >
                        {commonService.LabelDisplayText("Close")}
                    </button>
                    {IsPaying === false ? (
                        <button className="post-select-btn" onClick={() => PayNowClick()}>
                            {commonService.LabelDisplayText("PayNow")}
                        </button>
                    ) : (
                        <button
                            className="post-select-btn"
                            type="button"
                            disabled="disabled"
                        >
                            <Spinner animation="border" size="sm" /> {commonService.LabelDisplayText("Wait")}
                        </button>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default Fees;
